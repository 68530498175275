import { combineReducers } from 'redux';

import itineraryReducer from './itinerary/itinerary.reducer';
import paymentReducer from './payment/payment.reducer';
import methodReducer from './methodPayment/methodPayment.reducer';
import creditCardReducer from './creditCard/reducer';
import voucherReducer from './Voucher/voucher.reducer';
import activatedReducer from './isActiveVoucher/methodPayment.reducer'
import checkStatusPaymentReducer from './checkStatusPayment/checkStatusPayment.reducer';

const rootReducer = combineReducers({
  itinerary: itineraryReducer,
  payment: paymentReducer,
  method: methodReducer,
  creditCard: creditCardReducer,
  voucher: voucherReducer,
  activatedVoucher:activatedReducer,
  checkStatusPayment: checkStatusPaymentReducer,
});

export default rootReducer;
