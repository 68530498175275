import itineraryTypes from './itinerary.types'
import config from '../../config'
import axios from 'axios'

export const fetchItinerary = async link => {
  // const urls = config.baseUrlLive + `/api/itinerary${url}`;
  // console.log(link)
  // if (link==='/not-found'){
  //   return;
  // }
  try {
    //failed exampled
    // const url_failed = config.baseUrl + `/api/itinerary/44bf45da-8c65-4964-a0cc-e56be9c8779e`
    //success example
    const url = config.baseUrl + `/api/itinerary/${link}`
    // console.log(url)
    // const url = config.baseUrl + `/api/itinerary/96cfdff1-0619-4863-89c9-f8bc9dceda9a`
    // console.log(url)
    const res = await axios.get(url)
    const { data } = res
    // const data = await res.json();
    // console.log(data, 'data')
    return data
  } catch (error) {
    const data = error.response.data
    return data
  }
}

export const fetchItineraryStart = payload => ({
  type: itineraryTypes.FETCH_ITINERARY_START,
  payload
})

export const fetchItinerarySuccess = itinerary => ({
  type: itineraryTypes.FETCH_ITINERARY_SUCCESS,
  payload: itinerary
})

export const fetchItineraryFailed = errorMessage => ({
  type: itineraryTypes.FETCH_ITINERARY_FAILURE,
  payload: errorMessage
})
