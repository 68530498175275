import { takeLatest, call, all, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  POST_CHECK_STATUS_SUCCESS,
  POST_CHECK_STATUS_REQUEST,
} from './checkStatusPayment.types';

import config from 'config';
import axios from 'axios';

export function* postCheckStatus({ data }) {
  const options = {
    method: 'POST',
    timeout: 12000,
    baseURL: config.baseUrl + `/api/payment/checkStatus`,
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const { data } = yield call(axios, options);

    if (data?.status === 'ERROR') throw new Error(data?.message);
    if (data?.status === 'OK') toast.success(data?.message);
    yield put({ type: POST_CHECK_STATUS_SUCCESS, data });
  } catch (error) {
    console.log(error);
    // yield put(paymentFailure(message))
  }
}

export function* onCheckStatus() {
  yield takeLatest(POST_CHECK_STATUS_REQUEST, postCheckStatus);
}

export function* checkStatusPaymentWatcher() {
  yield all([call(onCheckStatus)]);
}
