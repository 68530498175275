import { toast } from 'react-toastify'

const errorHandler = error => {
  let message
  let status

  // axios error handler
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
    status = error.response.status || error.code || error.status

    // if backend sent more detail error at "title", "violations"
    const {title, violations} = error.response.data

    if (violations && violations[0]) {
      message = violations.map(({ field, message }) => `${field}: ${message}`).join(', ')
      if (title) message = `${title} - ${message}`
    }

  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log(error.request)
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log('Error', error.message)
  }
  // trace the error for debugging purpose
  console.trace(error)

  // get default message of error
  if (!message) message = status ? `${status}: ${error.message}` : error.message

  // show error on toast to user
  toast.error(message)
  
  return message
}

export default errorHandler
