import paymentTypes from './payment.types'

const INITIAL_STATE = {
  payment: null,
  isFetching: false,
  errorMessage: undefined
}

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case paymentTypes.PAYMENT_START:
      return {
        ...state,
        isFetching: true
      }
    case paymentTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payment: action.payload
      }
    case paymentTypes.PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      }
      case paymentTypes.PAYMENT_CHECK_STATUS:
        return {
          ...state,
          isFetching: false,
          payment: action.payload
        }
    default:
      return state
  }
}

export default paymentReducer
