import { takeEvery, call, put } from 'redux-saga/effects';
import errorHandler from 'utils/errorHandler';
import {
  paymentFetch,
  paymentSuccess,
  paymentFailure,
  paymentCheckStatus,
} from './payment.action';
import config from 'config';
import axios from 'axios';
import paymentTypes from './payment.types';
import { POST_CHECK_STATUS_SUCCESS } from 'redux/checkStatusPayment/checkStatusPayment.types';

export function* creditCardPayment(action) {
  try {
    const optionsCheckStatus = {
      method: 'POST',
      timeout: 12000,
      baseURL: config.baseUrl + `/api/payment/checkStatus`,
      data: action.payload.payment_url_id,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const resp = yield call(axios, optionsCheckStatus);
    // console.log(resp);
    if (resp?.data.message === 'Waiting for payment') {
      const response = yield call(paymentFetch, action.payload);
      if (response.data.status === 'Error') {
        throw new Error(response.data.message);
      }
      yield put(paymentSuccess(response));
    } else {
      // console.log('res2', resp.data)
      yield put({ type: POST_CHECK_STATUS_SUCCESS, data: resp.data });
      yield put(paymentCheckStatus(resp.data));
    }
  } catch (error) {
    const message = errorHandler(error);
    yield put(paymentFailure(message));
  }
}

const paymentSaga = [takeEvery(paymentTypes.PAYMENT_START, creditCardPayment)];

export default paymentSaga;
