import VoucherActive from './methodPayment.types'
const INITIAL_STATE = {
  isActive:false
}

const activatedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VoucherActive.IS_ACTIVE:
      return {
        ...state,
        isActive: action.payload
      }
    default:
      return state
  }
}

export default activatedReducer
