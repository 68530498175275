import React, { Fragment } from 'react'

const PageLoader = ({ loading }) => (
  <Fragment>
    {loading ? (
      <main className="overlay">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </main>
    ) : null}
  </Fragment>
)

export default PageLoader
