export const LINKAJA = 'LinkAja';
export const INDODANA = 'INDODANA';
export const KREDIVO = 'Kredivo';
export const BRI_CERIA = 'BRI Ceria';
export const MANDIRI_VA = 'MANDIRI VA';
export const BCA_KlikPay = 'BCA KlikPay';
export const CIMB_CLICKS = 'CIMB Clicks';
export const BRI_EPAY = 'BRI Epay';
export const DANAMON = 'DANAMON';
export const DOKU_VA = 'DOKU VA';
export const BCA_VIRTUAL_ACCOUNT = 'BCA Virtual Account';
export const BNI_VIRTUAL_ACCOUNT = 'BNI Virtual Account';
export const PERMATA_VIRTUAL_ACCOUNT = 'PERMATA Virtual Account';
export const DOKU_CARD = 'DOKU Card';
export const TRAVELLING_CARD = 'TRAVELING Card';
export const SHOPEE_PAY = 'SHOPEE Pay';
export const MIDTRANS_CARD = 'MIDTRANS Card';
export const AKULAKU = 'AKULAKU';
