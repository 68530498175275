import methodPaymentTypes from './methodPayment.types'
import * as METHOD_NAME from 'constants/methodNames';
const INITIAL_STATE = {
  methodName: METHOD_NAME.DOKU_CARD
}

const methodReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case methodPaymentTypes.METHOD_NAME:
      return {
        ...state,
        methodName: action.payload
      }
    default:
      return state
  }
}

export default methodReducer
