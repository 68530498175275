const dev = process.env.NODE_ENV === 'development';

const prodConfig = {
  // baseUrl: 'https://opa.garuda-indonesia.com:81',
  // baseUrl: 'http://10.90.35.241:80',
  baseUrl: 'https://paymentlink.garuda-indonesia.com',
  // baseUrl: 'https://paymentlink.atibusinessgroup.com',
};

const devConfig = {
  // baseUrl: 'https://opa.garuda-indonesia.com:81'
  // baseUrl: 'http://10.90.35.241:80',
  baseUrl: 'https://paymentlink.atibusinessgroup.com',
  // baseUrl: 'https://opa-ga.atibusinessgroup.com:8001',
};

const appConfig = {
  baseUrl: dev ? devConfig.baseUrl : prodConfig.baseUrl,
  baseUrlLive: 'https://paymentlink.garuda-indonesia.com',
  daySessionExpiration: 1,
};

export default appConfig;
